import { action, observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { PurchaseOrder } from './PurchaseOrder';
import { Article } from './Article';
import { ProductionBatchStore } from './ProductionBatch';

export class PurchaseOrderLine extends Model {
    static backendResourceName = 'purchase_order_line';
    static omitFields = ['ready', 'readyStatus'];

    @observable id = null;
    @observable quantity = 0;
    @observable exactReceived = 0;
    @observable ready = null;
    @observable readyStatus = null;


    // When moving through the kanbanBoard we need an actionCount for the modal to indicate the amount the modal action is for
    @observable _actionQuantity = this.quantity

    get unfinishedQuantity() {
        if (!this.productionBatches) {
            return 0;
        }
        return this.productionBatches.models.reduce((partialSum, batch) => {
            if (batch.readyDate) {
                return partialSum - batch.quantity;
            }
            return partialSum;
        }, this.quantity - this.exactReceived);
    }

    get finishedQuantity() {
        if (!this.productionBatches) {
            return 0;
        }
        return this.productionBatches.filter(batch => batch.readyDate).reduce((partialSum, batch) => partialSum + batch.open, 0);
    }

    finishProduction() {
        let data = {};
        if (this._actionQuantity && this._actionQuantity > 0) {
            data['quantity'] = this._actionQuantity;
            return this.api.post(`${this.url}finish_production/`, data).then(action(res => {

                window.viewStore.showSaveNotification();

            }))
                .catch(action(err => {
                    // if (err.valErrors) {
                    //     this.parseValidationErrors(err.valErrors);
                    // }
                    throw err;
                }));
        }
    }

    relations() {
        return {
            purchaseOrder: PurchaseOrder,
            article: Article,
            productionBatches: ProductionBatchStore,
        };
    }
}

export class PurchaseOrderLineStore extends Store {
    Model = PurchaseOrderLine;
    static backendResourceName = 'purchase_order_line';
}
