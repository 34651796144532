export default {
    nav: {
        main: {
            assets: 'Master data',
            supplyChain: 'Supply Chain',
            janKrediet: 'Jan Krediet',
            supplier: 'Supplier'
        },
        assets: {
            suppliers: 'Suppliers',
            articles: 'Articles',
            customers: 'Customers',
            transportCompanies: 'Transport Companies',
            users: 'Users',
            integrations: 'Integrations',
            exactErrors: 'Exact Errors',
            globalValues: 'Settings',
            globalFiles: 'Special files',
            emailTemplates: 'Email templates',
        },
        audit: {
            accessLog: 'Access Log',
        },
        account: {
            account: 'Account',
            changelog: 'Changelog'
        },
        supplyChain: {
            distributionManager: 'Planning',
            salesOrders: 'Sales Orders',
            distributionOrders: 'Distribution Orders',
            purchaseOrders: 'Purchase Orders',
            transportOrders: 'Transport Orders',
            articles: 'Articles',
        },
        janKrediet: {
            orders: 'Orders',
            inboundOrders: 'Inbound Orders',
            outboundOrders: 'Outbound Orders',
        },
        supplier: {
            dashboard: 'Dashboard',
            articles: 'Articles',
        },
        prompt: {
            leave: 'You have unsaved changes, if you continue your changes will be lost',
            keepEditing: 'Keep editing',
            continue: 'Continue'
        }
    },
    user: {
        overview: {
            title: 'Users',
            addButton: 'Add User',
        },
        create: {
            title: 'Create User',
        },
        edit: {
            title: 'Edit User',
        },
        field: {
            fullName: { label: 'Name' },
            firstName: { label: 'First Name' },
            lastName: { label: 'Last Name' },
            email: { label: 'Email' },
            password: { label: 'Password' },
            dateJoined: { label: 'Added At' },
            groups: {
                label: 'Groups',
                value: {
                    superuser: 'Superuser',
                    admin: 'Admin',
                    manager: 'Manager',
                    purchaser: 'Purchaser',
                    planner: 'Planner',
                    supplier: 'Supplier',
                    distributor: 'Distributor',
                    transport_partner: 'Transport Partner',
                },
                empty: 'No groups assigned',
            },
            supplier: {
                label: 'Supplier',
                none: 'No supplier assigned',
            },
            transportCompany: {
                label: 'Transport Company',
                none: 'No transport company assigned',
            },
            language: {
                label: 'Language'
            },
            isActive: {
                label: 'Active',
            },
        },
        login: {
            title: 'Login',
            forgotPasswordLink: 'Forgot password?',
            errors: {
                invalidCredentials: 'Username or password do not match',
                unknown: 'Unknown error, status code: {{status}}',
            },
            loginButton: 'Log In',
        },
        passwordForgot: {
            title: 'Forgot password',
            requestButton: 'Send Email',
            requestedEmailText:
                'An email with a reset code has been sent to this email address.',
            loggedInError: 'You are logged in. Password forgot only works when you are not logged in.',
        },
        resetPassword: {
            title: 'Reset password',
            resetButton: 'Reset',
            saveSuccess: 'Password is successfully reset.',
            loggedInError: 'You are logged in. Password reset only works when you are not logged in.',
        },
        changePassword: {
            title: 'Change Password',
            field: {
                passwordOld: {
                    label: 'Current password',
                },
                passwordNew: {
                    label: 'New password',
                },
            },
            saveButton: 'Save',
        },
        account: {
            title: 'Account Details',
            logoutButton: 'Log Out',
            info: {
                title: 'Account Info',
            },
            password: {
                title: 'Change Password',
            },
            logout: {
                title: 'Log Out',
                confirm: 'Are you sure you want to logout?'
            },
        },
    },
    myFilter: {
        custom: 'Custom',
        action: {
            unsetDefault: 'Unset Default',
            setDefault: 'Set Default',
            setCurrent: 'Save Current Filters',
            delete: 'Delete',
        },
    },
    globalValue: {
        edit: {
            title: 'Edit value of global setting',
        },
        overview: {
            title: 'Global settings',
        },
        field: {
            key: {
                label: 'Setting name',
            },
            value: {
                label: 'Value',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    globalFile: {
        edit: {
            title: 'Edit special file',
        },
        overview: {
            title: 'Special files',
        },
        field: {
            key: {
                label: 'Name for file',
            },
            value: {
                label: 'File',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    accessLogEntry: {
        overview: {
            title: 'Audit log',
        },
        field: {
            originalUser: {
                label: 'Original user',
            },
            masqueradingAsUser: {
                label: 'Masquerading as user',
            },
            ipAddress: {
                label: 'IP address',
            },
            method: {
                label: 'HTTP method',
            },
            endpoint: {
                label: 'Endpoint',
            },
            statusCode: {
                label: 'Status code',
            },
            additionalInfo: {
                label: 'Extra information',
            },
            requestTime: {
                label: 'Time of access',
            },
        },
    },
    tooltips: {
        masquerade: 'Masquerade',
        edit: 'Edit',
        delete: 'Delete',
        view: 'View',
        restore: 'Restore',
        send: 'Send',
    },
    form: {
        repeat: 'Repeat',
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Other',
            none: 'No file uploaded yet',
        },
        saveButton: 'Save',
        sendButton: 'Send',
        sortButton: 'Sort',
        deleteButton: 'Delete',
        multiPick: {
            searchPlaceholder: 'Select…',
            selectedText: '$1 of $2 selected',
            noneSelectedText: 'None selected',
            selectAllButton: 'All',
            selectNoneButton: 'None',
        },
        notifications: {
            saveSuccess: 'Saved successfully',
            deleteSuccess: 'Deleted successfully',
            saveError: 'Error with saving ({{status}})',
            saveValError: 'Not all information is filled in correctly',
            saveAuthError: 'Invalid credentials',
            newAppVersion:
                'New version available, click to reload page as soon as possible',
        },
        submitButton: 'Submit',
        cancelButton: 'Cancel',
        addButton: 'Add',
        startDate: 'Start Date',
        endDate: 'End Date',
        deleteConfirmation: 'Are you sure you want to delete this entity?',
        restoreConfirmation: 'Are you sure you want to restore this entity?',
        yes: 'Yes',
        either: 'Either',
        no: 'No',
        search: 'Search',
    },
    crash: {
        heading: 'Application crashed',
        subHeading1: 'We’re very sorry, but the application has crashed.',
        subHeading2: 'Our team has been notified.',
        reload: 'Reload page'
    },
    emailTemplate: {
        overview: {
            title: 'Email templates',
            addButton: 'Add email template',
        },
        create: { title: 'Create mail template' },
        edit: { title: 'Edit mail template' },
        add: { title: 'Add mail template' },
        field: {
            id: { label: 'ID' },
            isDefault: { label: 'Is default' },
            name: { label: 'Name' },
            emailType: { label: 'Email type' },
            subject: { label: 'Subject' },
            textContent: { label: 'Text variant of email', placeholder: 'Some mail clients only support text' },
            senderEmail: { label: 'Sender email' },
        }
    },
    emailTypes: {
        password_reset: 'Password reset',
        user_activation: 'User activation',
        transport_order_request: 'Transport order request',
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{weekNumber}}',
        },
        weekDay: {
            monday: 'Mo',
            tuesday: 'Tu',
            wednesday: 'We',
            thursday: 'Th',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'Su',
        },
        month: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
        },
        period: {
            am: 'AM',
            pm: 'PM',
        },
    },
    pageNotFoundMessage: 'Page not found',
    brandingMessage: 'Powered by Code Yellow',
    purchaseOrder: {
        overview: {
            title: 'Purchase Orders',
            acceptButton: 'Accept',
            rejectButton: 'Reject',
            addLine: 'Add Line',
        },
        field: {
            supplier: { label: 'Supplier' },
            orderDate: { label: 'Order' },
            productionDate: { label: 'Production' },
            loadDate: { label: 'Load' },
            unloadDate: { label: 'Unload' },
            readyStatus: {
                label: 'Ready Status',
                value: {
                    '0_pending': 'Pending',
                    '1_open': 'Open',
                    '2_partial': 'Partial',
                    '3_complete': 'Complete',
                },
            },
            notes: { label: 'Notes' },
            threads: { label: 'Note contains' },
            unresolvedThreads: { label: 'Has' }
        },
    },
    purchaseOrderLine: {
        field: {
            article: { label: 'Article' },
            quantity: { label: 'Quantity' },
            ready: { label: 'Ready' },
        },
    },
    salesOrder: {
        overview: {
            title: 'Sales Orders',
            bulkCreatePurchaseOrders: 'Create Purchase Orders',
            short: '{{count}} short',
            delayIssuePartially: 'Delay Partially',
            delayIssueFully: 'Delay Fully',
            noIssues: 'There are currently no delivery issues.',
            addresses: {
                order: 'Order Address',
                delivery: 'Delivery Address',
                invoice: 'Invoice Address',
            },
        },
        field: {
            number: { label: 'SO Number' },
            customer: { label: 'Customer' },
            orderDate: { label: 'Order' },
            deliveryDate: { label: 'Delivery' },
            notes: { label: 'Notes' },
            deliveryIssue: { label: 'Delivery Issue' },

            deliveryName: { label: 'Name' },
            deliveryPhoneNumber: { label: 'Phone Number' },
            deliveryAddress: { label: 'Address' },
            deliveryStreet: { label: 'Street' },
            deliveryNumber: { label: 'Number' },
            deliveryNumberSuffix: { label: 'Suffix' },
            deliveryZipCode: { label: 'ZIP Code' },
            deliveryCity: { label: 'City' },
            deliveryCountry: { label: 'Country' },

            invoiceName: { label: 'Company' },
            invoiceEmailAddress: { label: 'Email Address' },
            invoicePhoneNumber: { label: 'Phone Number' },
            invoiceAddress: { label: 'Address' },
            invoiceStreet: { label: 'Street' },
            invoiceNumber: { label: 'Number' },
            invoiceNumberSuffix: { label: 'Suffix' },
            invoiceZipCode: { label: 'ZIP Code' },
            invoiceCity: { label: 'City' },
            invoiceCountry: { label: 'Country' },

            deliveryStatus: {
                label: 'Delivery Status',
                value: {
                    open: 'Open',
                    partial: 'Partial',
                    complete: 'Complete',
                    cancelled: 'Cancelled',
                },
            },
            invoiceStatus: {
                label: 'Invoice Status',
                value: {
                    open: 'Open',
                    partial: 'Partial',
                    complete: 'Complete',
                    cancelled: 'Cancelled',
                },
            },

            description: { label: 'Description' },
            salesPerson: { label: 'Sales Person' },
            customerReference: { label: 'Customer Ref' },
            isBol: { label: 'Is Bol.com' },
        },
        filter: {
            late: {
                label: 'Late',
                info: 'If articles still need to come from the supplier the transport should have been scheduled already.',
            },
            upcoming: {
                label: 'Urgent',
                info: 'If articles still need to come from the supplier the transport has to be scheduled today.',
            },
            plannable: {
                label: 'Plannable',
                info: 'There are at least some articles to plan.',
            },
        },
    },
    salesOrderLine: {
        field: {
            article: { label: 'Article' },
            quantity: { label: 'Quantity' },
            distributed: { label: 'Planned' },
            transported: { label: 'Transported' },
            delivered: { label: 'Delivered' },
        },
    },
    article: {
        overview: {
            title: 'Articles',
            orderModal: {
                title: 'Add to Basket: {{article}}',
            },
            addToBasket: 'Add to Basket',
            addDemandToBasket: 'Add Demand to Basket',
            bulkAddToBasket: 'Add to Basket ({{count}})',
            bulkAddDemandToBasket: 'Add Demand to Basket ({{count}})',
            shoppingBasket: {
                empty: 'There is nothing in your shopping basket yet.',
                purchaseButton: 'Purchase',
                discard_one: 'Leaving this page will discard your shopping basket containing {{count}} item, are you sure you want to leave?',
                discard_other: 'Leaving this page will discard your shopping basket containing {{count}} items, are you sure you want to leave?',
            },
        },
        create: { title: 'Add Article' },
        edit: { title: 'Edit Article' },
        field: {
            name: { label: 'Name' },
            supplier: { label: 'Supplier' },
            suppliers: { label: 'Suppliers' },
            productionTime: { label: 'Production Time (d)' },
            transportTime: { label: 'Transport Time (d)' },
            fromStock: { label: 'From DC Stock' },
            minStockLevel: { label: 'Min Stock' },
            maxStockLevel: { label: 'Max Stock' },
            currentStock: { label: 'Current Stock' },
            projectedStock: { label: 'Projected Stock' },
            demand: { label: 'Demand' },
            cbm: { label: 'CBM' },
            kg: { label: 'kg' },
        },
        button: {
            filter: {
                activate: 'Filter SOs on article',
                deactivate: 'Turn off filter on SOs',
            }
        },
    },
    supplier: {
        overview: { title: 'Suppliers' },
        create: { title: 'Add Supplier' },
        edit: { title: 'Edit Supplier' },
        field: {
            name: { label: 'Name' },
            emailAddress: { label: 'Email Address' },
            phoneNumber: { label: 'Phone Number' },
            address: { label: 'Address' },
            street: { label: 'Street' },
            number: { label: 'Number' },
            numberSuffix: { label: 'Suffix' },
            zipCode: { label: 'ZIP Code' },
            city: { label: 'City' },
            country: { label: 'Country' },
            dropshipment: { label: 'Dropshipment' },
        },
    },
    supplierDashboard: {
        column: {
            newItems: { label: 'New' },
            confirmedItems: { label: 'Confirmed' },
            inProduction: { label: 'In production' },
            readyForTransport: { label: 'Ready to transport' },
            confirmTransport: { label: 'Transport orders to confirm' },
            loadingExpected: { label: 'Transport orders' },
            done: { label: 'Shipped' },
            dropshipPlanned: { label: 'Shipment planned' },
            dropshipDelivered: { label: 'Delivered to customer' },
        },
        statusLabel: {
            toConfirm: 'To Confirm',
            open: 'In Production',
            inProduction: 'Partial',
            complete: 'Complete',
        },
        expected: 'Expected',
        actionModal: {
            header: {
                confirmedItems: 'Confirm Purchase Order',
                readyForTransport: 'Confirm articles are ready for transport',
                done: 'Confirm items are loaded and shipped by transport company',
                dropshipPlanned: 'Confirm transport for items',
                dropshipDelivered: 'Confirm transport received',
            }
        }
    },
    customer: {
        overview: { title: 'Customers' },
        create: { title: 'Add Customer' },
        edit: { title: 'Edit Customer' },
        field: {
            name: { label: 'Name' },
            emailAddress: { label: 'Email Address' },
            phoneNumber: { label: 'Phone Number' },
            address: { label: 'Address' },
            street: { label: 'Street' },
            number: { label: 'Number' },
            numberSuffix: { label: 'Suffix' },
            zipCode: { label: 'ZIP Code' },
            city: { label: 'City' },
            country: { label: 'Country' },
        },
    },
    integration: {
        overview: { title: 'Integrations' },
        create: { title: 'Add Integration' },
        edit: { title: 'Edit Integration' },
        field: {
            id: { label: 'ID' },
            type: {
                label: 'Type',
                value: {
                    exact: 'Exact',
                    jan_krediet: 'Jan Krediet',
                },
            },
            description: { label: 'Description' },
            errorCount: { label: '# Errors' },
        },
    },
    exactIntegration: {
        overview: {
            activateButton: 'Activate',
            activateResult: {
                ok: 'Exact activated succesfully',
                error: 'Exact activation error: {{error}}',
            },
            infoModal: {
                title: 'Exact Sync Info',
                readHeader: 'Read Topics',
                writeHeader: 'Write Topics',
                topic: {
                    account: 'Accounts',
                    address: 'Addresses',
                    supplier_item: 'Supplier Items',
                    item_extra_field: 'Item Extra Fields',
                    item_warehouse: 'Item Warehouses',
                    item: 'Items',
                    sales_order_line: 'Sales Order Lines',
                    sales_order: 'Sales Orders',
                    purchase_order_line: 'Purchase Order Lines',
                    purchase_order: 'Purchase Orders',
                    goods_receipt: 'Goods Receipt',
                    goods_delivery: 'Goods Delivery',
                },
            },
        },
        field: {
            country: {
                label: 'Country',
                value: {
                    nl: 'NL',
                    be: 'BE',
                    de: 'DE',
                },
            },
            division: { label: 'Division' },
        },
    },
    janKredietIntegration: {
        field: {
            apiKey: { label: 'API Key' },
            isTest: { label: 'Is Test' },
        },
    },
    exactError: {
        overview: {
            title: 'Exact Errors',
            topicHeader: {
                account_one: 'Account ({{count}})',
                account_other: 'Accounts ({{count}})',
                item_one: 'Item ({{count}})',
                item_other: 'Items ({{count}})',
                supplier_item_one: 'Supplier Item ({{count}})',
                supplier_item_other: 'Supplier Items ({{count}})',
                sales_order_one: 'Sales Order ({{count}})',
                sales_order_other: 'Sales Orders ({{count}})',
                sales_order_line_one: 'Sales Order Line ({{count}})',
                sales_order_line_other: 'Sales Order Lines ({{count}})',
                purchase_order_one: 'Purchase Order ({{count}})',
                purchase_order_other: 'Purchase Orders ({{count}})',
                purchase_order_line_one: 'Purchase Order Line ({{count}})',
                purchase_order_line_other: 'Purchase Order Lines ({{count}})',
            },
        },
        field: {
            integration: { label: 'Integration' },
            topic: {
                label: 'Topic',
                value: {
                    account: 'Account',
                    item: 'Item',
                    supplier_item: 'Supplier Item',
                    sales_order: 'Sales Order',
                    sales_order_line: 'Sales Order Line',
                    purchase_order: 'Purchase Order',
                    purchase_order_line: 'Purchase Order Line',
                },
            },
            status: {
                label: 'Status',
                value: {
                    create_error: 'Create Error',
                    update_error: 'Update Error',
                    delete_error: 'Delete Error',
                },
            },
            error: { label: 'Error' },
            payloadCount: { label: '# Occurrences' },
            blockingCount: { label: '# Blocking' },
        },
    },
    exactPayload: {
        field: {
            exactId: { label: 'Exact ID' },
        },
    },
    json: {
        entries_one: '{{count}} entry',
        entries_other: '{{count}} entries',
    },
    transportCompany: {
        overview: { title: 'Transport Companies' },
        create: { title: 'Add Transport Company' },
        edit: { title: 'Edit Transport Company' },
        field: {
            name: { label: 'Name' },
            email: { label: 'Email' },
            noApprovalRequired: { label: 'No approval required' },
        },
    },
    stock: {
        overview: {
            title: 'Stock',
            date: 'Date',
        },
        field: {
            article: { label: 'Article' },
            startDate: { label: 'Start Date' },
            endDate: { label: 'End Date' },
            inProduction: { label: 'In Production' },
            toTransport: { label: 'To Transport' },
            inTransport: { label: 'In Transport' },
            toDistribute: { label: 'To Distribute' },
        },
    },
    distributionOrder: {
        overview: { title: 'Distribution Orders' },
        create: { title: 'Add Distribution Order' },
        edit: {
            title: 'Edit Distribution Order',
            allocateInTransport: 'Allocate from Transport',
            allocateToTransport: 'Allocate from Supplier',
            allocateInStock: 'Allocate from Stock',
            chooseDistributionOrTransport: {
                header: 'Distribution or Transport',
                content: 'You have selected an article that can be added both to the distribution order and the transport order. Choose to which one you would want to add it.',
                addToDistribution: 'Distribution',
                addToTransport: 'Transport',
                addToBoth: 'Both',
            },
            confirmPartial: {
                header: 'Partial Distribution Order',
                content: 'Some lines in the distribution order have not been allocated yet which means they cannot be saved. Would you like to save the distribution order as a partial distribution order without these lines?',
                yesAndBlock: 'Yes & Block',
            },
            checkUnblock: {
                header: 'Unblock Distribution Order',
                content: 'All lines in the distribution order are allocated but the order is still marked as blocked. Would you like to unblock the distribution order?',
            },
            confirmDelete: {
                header: 'Delete Distribution Order',
                content: 'None of the lines in the distribution order have been allocated and thus cannot be saved. Since a distribution order must have lines it also cannot be saved. Would you like to delete the distributiion order instead?',
            },
            confirmUnallocate: {
                header: 'Unallocate Distribution Order',
                content: 'You are trying to change the delivery type of a distribution order that has allocations. This will make your current allocations invalid and thus they have to be deleted. Are you sure you want to do this?',
            },
            saveAndSend: 'Save & Send',
        },
        field: {
            deliveryDate: { label: 'Communicated Delivery' },
            expectedDeliveryDate: { label: 'Expected Delivery' },
            expectedDeliveryDifference: {
                value: {
                    early: 'Early',
                    on_time: 'On Time',
                    late: 'Late',
                },
            },
            deliveryStatus: {
                label: 'Delivery Status',
                value: {
                    to_transport: 'To Transport',
                    in_transport: 'In Transport',
                    blocked: 'Blocked',
                    to_plan: 'To Plan',
                    planned: 'Planned',
                    delivered: 'Delivered',
                },
            },
            transportOrders: { draft: 'Draft TOs' },
            directToCustomer: {
                label: 'Delivery Type',
                value: {
                    true: 'Direct',
                    false: 'Distributed',
                },
            },
            customerType: {
                label: 'Customer Type',
                value: {
                    business: 'B2B',
                    customer: 'B2C',
                    pick_up: 'Pick Up',
                    parcel: 'Parcel',
                },
            },
            hasShortage: { info: 'There are not enough items available to finish the order, please verify available items and update DO' },
            blocked: { label: 'Blocked' },
        },
    },
    distributionOrderLine: {
        field: {
            article: { label: 'Article' },
            quantity: { label: 'Quantity' },
            deliveryIssue: {
                label: 'Delivery Issue',
                value: '{{count}} until {{date}}',
            },
            transportBatch: { label: 'Source' },
        },
    },
    transportOrder: {
        create: { title: 'Add Transport Order' },
        edit: {
            title: 'Edit Transport Order',
            leftoverLoad: 'Leftover Load',
            saveAndSend: 'Save & Send',
        },
        overview: {
            title: 'Transport Orders',
            addTransportOrderButton: 'Add Transport Order',
            addTransportOrderModal: { title: 'Add Transport Order' },
            date: 'Date',
            open: {
                totalCbm: '{{value}} Total',
                remainingCbm: '{{value}} Remaining',
                transportButton: 'Transport',
                empty: 'There is nothing that needs to be transported.',
            },
            markDone: 'Mark Done',
        },
        field: {
            transportCompany: { label: 'Transport Company' },
            loadDate: { label: 'Load' },
            unloadDate: { label: 'Unload' },
            totalCbm: { label: 'Total CBM' },
            totalKg: { label: 'Total kg' },
            notes: { label: 'Notes' },
            startDate: { label: 'Start' },
            activities: { label: 'Activities' },
            isDone: { label: 'Is Done' },
            _emailTemplate: { label: 'Email Template' },
            rejectReason: { label: 'Reject reason' },
            licensePlate: { label: 'License Plate' },
            loadPlannedAt: { label: 'Load planned at' },
            unloadPlannedAt: { label: 'Unload planned at' },
            loadDoneAt: { label: 'Load done at' },
            unloadDoneAt: { label: 'Unload done at' },
        },
    },
    productionBatch: {
        field: {
            quantity: { label: 'Quantity' },
            readyDate: { label: 'Ready' },
        },
    },
    thread: {
        actions: {
            reply: 'Reply',
            resolve: 'Resolve',
            resolveAndReply: 'Reply and unresolve',
            hideComments: 'Hide comments',
            showComments: 'Show {{count}} comments',
        },
        field: {
            reply: { label: 'Reply...' },
            message: { label: 'New note...' },
            notes: {
                more: '{{count}} more',
                none: 'Add a note...',
            }
        },
        button: {
            showResolved: 'Show resolved notes...',
            hideResolved: 'Hide resolved notes...',
            hasUnresolved: 'Resolved notes',
            hasResolved: 'Unresolved notes',
        }
    },
    transportOrderLine: {
        field: {
            quantity: { label: 'Quantity' },
            productionBatch: { label: 'Ready' },
        },
    },
    activity: {
        field: {
            type: {
                label: 'Type',
                value: {
                    load: 'Load',
                    unload: 'Unload',
                },
            },
            account: { label: 'Account' },
            plannedAt: { label: 'Planned At' },
            doneAt: { label: 'Done At' },
        },
    },
    activityLine: {
        field: {
            quantity: { label: 'Quantity' },
        },
    },
    distributionManager: {
        stock: 'Stock',
        articles: 'Articles',
        notAllocated: 'Not Allocated',
        setFilter: {
            article: 'Filter on article {{code}} {{name}}',
            transport: 'Filter on articles ready to transport',
            distributionOrder: 'Filter on articles that can be allocated for distribution',
        },
        clearFilter: {
            article: 'Clear filter on article {{code}} {{name}}',
            transport: 'Clear filter on articles ready to transport',
            distributionOrder: 'Clear filter on articles that can be allocated for distribution',
        },
        pendingUnload: {
            label: 'Pending Transport',
            info: 'You have allocated articles from the supplier for which transport still needs to be planned. You first need to plan this transport before you can save this distribution order.',
        },
        sendModal: {
            title: 'Send Transport Order',
        },
        newTransportOrder: 'New Transport Order',
        transportedQuantityBelowDistributed: 'Quantity cannot be below distributed',
        hasUndistributedToCustomer: 'Articles go directly to the customer but are not fully distributed, this is not allowed.',
        canAllocateStatus: {
            fully: 'Can allocate fully',
            partially: 'Can allocate partially',
            not: 'Can not allocate',
            blocked: 'Blocked',
            wait: 'Waiting for supplier to finish production',
        }
    },
    transportBatch: {
        field: {
            distributed: { label: 'Distributed' },
            quantity: { label: 'Quantity' },
            load: { label: 'Load' },
            unload: { label: 'Unload' },
        },
    },
    janKredietOrder: {
        overview: { title: 'Orders' },
        field: {
            status: {
                label: 'Status',
                value: {
                    to_sync: 'To Sync',
                    synced: 'Synced',
                    sync_error: 'Sync Error',
                    to_release: 'To Release',
                    released: 'Released',
                    release_error: 'Release Error',
                    done: 'Done',
                },
            },
            error: { label: 'Error' },
            lines: {
                label: 'Lines',
                value_one: '{{count}} line',
                value_other: '{{count}} lines',
            },
            transportDate: { label: 'Transport' },
            distributionDate: { label: 'Distribution' },
        },
    },
    janKredietOrderLine: {
        field: {
            quantity: { label: 'Quantity' },
        },
    },
    janKredietInboundOrder: {
        overview: { title: 'Inbound Orders' },
        field: {
            status: {
                label: 'Status',
                value: {
                    to_sync: 'To Sync',
                    synced: 'Synced',
                    sync_error: 'Sync Error',
                    done: 'Done',
                },
            },
            error: { label: 'Error' },
            lines: {
                label: 'Lines',
                value_one: '{{count}} line',
                value_other: '{{count}} lines',
            },
            transportDate: { label: 'Transport' },
        },
    },
    janKredietInboundOrderLine: {
        field: {
            quantity: { label: 'Quantity' },
        },
    },
    janKredietOutboundOrder: {
        overview: { title: 'Outbound Orders' },
        field: {
            status: {
                label: 'Status',
                value: {
                    to_sync: 'To Sync',
                    synced: 'Synced',
                    sync_error: 'Sync Error',
                    done: 'Done',
                },
            },
            error: { label: 'Error' },
            lines: {
                label: 'Lines',
                value_one: '{{count}} line',
                value_other: '{{count}} lines',
            },
            distributionDate: { label: 'Distribution' },
        },
    },
    janKredietOutboundOrderLine: {
        field: {
            quantity: { label: 'Quantity' },
        },
    },
    load: {
        field: {
            plannedAt: { label: 'Planned At' },
            expectedAt: { label: 'Expected At' },
            doneAt: { label: 'Done At' },
            notes: { label: 'Notes' },
            packingList: { label: 'Packinglist' },
        },
    },
    unload: {
        field: {
            plannedAt: { label: 'Planned At' },
            expectedAt: { label: 'Expected At' },
            doneAt: { label: 'Done At' },
            notes: { label: 'Notes' },
            proofOfDelivery: { label: 'Proof of delivery' },
        },
    },
    emailToken: {
        acceptPage: {
            invalid: { message: 'It is not possible anymore to respond to this request. The response time has expired or a response was already given.' },
            accepted: { message: 'Thank you for accepting our request!' }
        },
        declinePage: {
            rejectButton: { label: 'Reject request' },
            rejectReason: { message: 'Please provide a reason for rejecting this transport request.' },
            invalid: { message: 'It is not possible anymore to respond to this request. The response time has expired or a response was already given.' },
            accepted: { message: 'Your response has been processed' }
        }
    }
};
